import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

class Redirecter extends React.Component {
  componentDidMount() {
    navigate(this.props.target)
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}

Redirecter.propTypes = {
  target: PropTypes.string.isRequired,
}

export default Redirecter
